import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCqJ3Fg9B6TGC8ylyauNZpf8afPWQPIp_U",
  authDomain: "test-70ce3.firebaseapp.com",
  databaseURL: "https://test-70ce3-default-rtdb.firebaseio.com",
  projectId: "test-70ce3",
  storageBucket: "test-70ce3.appspot.com",
  messagingSenderId: "674047184013",
  appId: "1:674047184013:web:d072279dec902b4301e6c4",
  measurementId: "G-70QRG3TSR6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider, signInWithPopup };