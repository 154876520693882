import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const QR = () => {
  const [qrCode, setQrCode] = useState('Henüz bir QR kod taranmadı');

  useEffect(() => {
    // Tarayıcı başlatılıyor
    const scanner = new Html5QrcodeScanner(
      'reader', 
      { fps: 10, qrbox: { width: 250, height: 250 } }, 
      false
    );

    // Tarama işlemi başlatma ve sonuç yakalama
    scanner.render(
      (result) => {
        if (result) {
          setQrCode(result); // QR kod okununca sonuç güncelleniyor
        }
      },
      (error) => {
        console.error('QR Kod Okuma Hatası: ', error); // Hatalar için log
      }
    );

    // Temizlik işlemi (komponent unmount olduğunda tarayıcıyı temizler)
    return () => {
      scanner.clear()
        .catch(error => console.error('QR Kod Tarayıcı temizleme hatası: ', error));
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div id="reader" style={{ width: '100%', maxWidth: '400px' }}></div>
      <label style={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}>
        {qrCode}
      </label>
    </div>
  );
};

export default QR;
